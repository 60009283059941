import React, { useState } from "react";
import { Modal, Button } from "antd";

const AppWorks = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div id="works" className="block worksBlock">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2> Where we are located</h2>
          <p style={{ color: "#fca311" }}>
            Click on the map icon to see our location
          </p>
        </div>

        <div className="contentHolder">
          <Button onClick={showModal}>
            <i className="fas fa-map-location"></i>
          </Button>
        </div>
        <Modal
          title="Turnibex Location"
          visible={isModalVisible}
          footer={null}
          onCancel={handleCancel}
        >
          <iframe
            width="100%"
            height="350"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="https://maps.google.com/maps?width=100%25&amp;height=350&amp;hl=en&amp;q=Unit%2013,%20Coventry%20Park,%20675%20Old%20Pretoria%20Road,%20Midrand,%201685+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          ></iframe>
        </Modal>
      </div>
    </div>
  );
};

export default AppWorks;
