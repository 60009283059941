import React from "react";

import { Row, Col, Card } from "antd";

const items = [
  {
    key: "1",
    title: "Trusted Manufacturers Worldwide",
    content:
      "We only work with trusted companies or manufacturers worldwide that comply with South African and International Standards to ensure credibility.",
  },

  {
    key: "2",
    title: "Timely and Reliable Deliveries",
    content:
      "We understand that our clients work on time, that’s why we make it a point to always deliver on time to industries and organizations around South Africa.",
  },

  {
    key: "3",
    title: "Top Quality Engineering Supplies",
    content:
      "We care about quality, that is why we only supply high-quality engineering supplies to meet your needs.",
  },
];

function AppAbout() {
  return (
    <div id="mission" className="block aboutBlock">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2> Our Mission</h2>
        </div>

        <div className="centerCard">
          <Row gutter={[16, 16]}>
            {items.map((item) => {
              return (
                <Col
                  span={24}
                  md={{ span: 8 }}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Card
                    hoverable
                    bordered
                    style={{
                      width: 350,
                      height: 300,
                      borderRadius: "10px",
                      color: "#111111",
                      paddingTop: "30px",
                    }}
                  >
                    <div className="content">
                      <h3 id="specialise">{item.title}</h3>
                      <p style={{ color: "#111111" }}>{item.content}</p>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </div>
  );
}

export default AppAbout;
