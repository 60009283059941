import React from "react";
import emailjs from "emailjs-com";
import { Row, Col, Card } from "antd";

function AppContact() {
  return (
    <div id="contact" className="block contactBlock">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2>Get in Touch</h2>
        </div>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col
            lg={{ span: 12 }}
            md={{ span: 24 }}
            style={{ marginTop: "15px" }}
          >
            <Card
              title="Contact Information"
              hoverable
              bordered
              style={{ width: 380 }}
            >
              <div className="contactInfoContainer">
                <div className="contactInfo">
                  <i className="fa fa-phone fa-xl"></i> <h3>PHONE</h3>{" "}
                </div>
                <p>081 4646 103</p>
              </div>

              <div className="contactInfoContainer">
                <div className="contactInfo">
                  <i className="fa fa-envelope fa-xl"></i> <h3>EMAIL</h3>{" "}
                </div>
                <p>
                  {" "}
                  <a href="mailto: sales@turnibex.co.za">
                    sales@turnibex.co.za
                  </a>
                </p>
              </div>

              <div className="contactInfoContainer">
                <div className="contactInfo">
                  <i className="fa fa-map fa-xl"></i> <h3>ADDRESS</h3>{" "}
                </div>
                <p>
                  Unit 13, Coventry Park, 675 Old Pretoria Road, <br /> Midrand,
                  1685
                </p>
              </div>
            </Card>
          </Col>
          <Col
            lg={{ span: 12 }}
            md={{ span: 24 }}
            style={{ marginTop: "15px" }}
          >
            <Card
              hoverable
              bordered
              style={{
                width: 380,
                color: "fffffff",
                backgroundColor: "#111111",
              }}
            >
              <div className="myForm">
                <form
                  action="https://formsubmit.co/aff1eb331e0df612c7eadda5f1deddeb"
                  method="POST"
                >
                  <label className="control-label">Name</label>
                  <input type="text" name="name" required />
                  <label>Email</label>
                  <input type="email" name="email" required />
                  <label>Message</label>
                  <textarea name="message" required />
                  <input type="submit" value="Send" />
                  <input
                    type="hidden"
                    name="_next"
                    value="https://turnibex.co.za/"
                  />
                  <input type="hidden" name="_subject" value="Turnibex form" />
                  <input
                    type="text"
                    name="_honey"
                    style={{ display: "none" }}
                  />
                  
                  <input type="hidden" name="_captcha" value="false" />
                  <input type="hidden" name="_autoresponse" value="Thank you for getting in touch with us, We will get back to you as soon as possible."/>
                </form>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default AppContact;
