import React from "react";
import AppHero from "../components/home/hero";
import AppAbout from "../components/home/about";
import AppFeature from "../components/home/feature";
import AppWorks from "../components/home/works";
import Mission from "../components/home/mission";
import AppPricing from "../components/home/pricing";
import AppContact from "../components/home/contact";

function AppHome() {
  return (
    <div className="main">
      <AppHero />
      <AppAbout />
      <Mission />
      <AppFeature />
      <AppWorks />
      {/* <FAQ /> */}
      <AppContact />
    </div>
  );
}

export default AppHome;
