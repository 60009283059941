import React from "react";

import { Row, Col, Card } from "antd";

const items = [
  {
    key: "1",
    icon: <i className="fa-solid fa-lightbulb"></i>,
    title: "Power Generation",
    content: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
  },

  {
    key: "2",
    icon: <i className="fa-solid fa-tint"></i>,
    title: "Oil & Gas",
    content:
      "Dolor consequuntur nam est harum culpa nisi porro dolore cum doloremque",
  },

  {
    key: "3",
    icon: <i className="fa-solid fa-industry"></i>,
    title: "Process and Industrial",
    content:
      "Voluptas ea sapiente, aliquid perspiciatis fugiat voluptate dolore maxime minus accusamus.",
  },
];

function AppAbout() {
  return (
    <div id="about" className="block aboutBlock">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2> About Turnibex</h2>
          <p>Engineering equipment supplier</p>
        </div>

        <div className="contentHolder">
          <p>
            Turnibex is a power generation, oil & gas, process & industrial,
            electronic and electrical distributor which supplies quality
            products and services to the engineering industry. Globally, we
            supply a comprehensive portfolio of branded, state-of-the-art
            products from major utility companies, industry, and distributors.
            We only deliver engineering supplies from manufacturers that comply
            with South African and International Standards. These include but
            are not limited to: ABB, Eaton, Siemens, Emerson, GLW Crimping
            Technology, FSK Electronics, Sick Sensor Intelligence, etc. Our
            commitment to timely and reliable deliveries, along with ensuring
            product availability, is paramount to Turnibex’s high level of
            customer satisfaction
          </p>
        </div>

        <div className="titleHolder">
          <h2> We Operate in the following sectors</h2>
        </div>

        <div className="centerCard">
          <Row gutter={[16, 16]}>
            {items.map((item) => {
              return (
                <Col
                  span={24}
                  md={{ span: 8 }}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Card
                    hoverable
                    bordered
                    style={{
                      width: 180,
                      height: 180,
                      backgroundColor: "#111111",
                      borderRadius: "50%",
                    }}
                  >
                    <div className="content">
                      <div className="icon">{item.icon}</div>
                      <h3 id="specialise">{item.title}</h3>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </div>
  );
}

export default AppAbout;
