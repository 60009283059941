import React from "react";
import { Button, Carousel } from "antd";

const items = [
  {
    key: "1",
    title: "Timely and Reliable Deliveries ",
    content:
      "We understand that our clients work on time, that’s why we make it a point to always deliver on time to industries and organizations around South Africa.",
  },

  {
    key: "2",
    title: "Trusted Manufacturers Worldwide ",
    content:
      "We only work with trusted companies or manufacturers worldwide that comply with South African and International Standards to ensure credibility.",
  },

  {
    key: "3",
    title: "Top Quality Engineering Supplies ",
    content:
      "We care about quality, that is why we only supply high-quality engineering supplies to meet your needs.",
  },
];

function AppHero() {
  return (
    <div id="hero" className="heroBlock">
      <div className="heroHeader">
        <h1>
          WELCOME TO <span> TURNIBEX</span>
        </h1>
        <h3> ENGINEERING EQUIPMENT SUPPLIER </h3>
      </div>
      <div className="heroCarousel">
        <div className="btnHolder">
          <a href="#about">
            <i class="fa fa-arrow-down fa-2xl"></i>
          </a>
        </div>
      </div>
    </div>
  );
}

export default AppHero;
