import React, { useState } from "react";
import { Anchor, Drawer, Button } from "antd";
import logo from "../../assets/images/logo-t.png";

const { Link } = Anchor;

function AppHeader() {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  return (
    <div className="container-fluid">
      <div className="header">
        <div className="logo">
          <a href="#hero">
            <img alt="Turnibex-Logo" src={logo} />
          </a>
        </div>

        <div className="mobileHidden">
          <Anchor targetOffset="65">
            <Link href="#hero" title="Home" />
            <Link href="#about" title="About" />
            <Link href="#mission" title="Mission" />
            <Link href="#feature" title="Suppliers" />
            <Link href="#works" title="Location" />
            <Link href="#contact" title="Contact" />
          </Anchor>
        </div>

        <div className="mobileVisible">
          <Button
            type="primary"
            onClick={showDrawer}
            style={{ position: "relative", left: "-20px" }}
          >
            <i className="fas fa-bars"></i>
          </Button>
          <Drawer placement="right" onClose={onClose} visible={visible}>
            <Anchor targetOffset="65">
              <Link href="#hero" title="Home" />
              <Link href="#about" title="About" />
              <Link href="#mission" title="Mission" />
              <Link href="#feature" title="Suppliers" />
              <Link href="#works" title="Location" />
              <Link href="#contact" title="Contact" />
            </Anchor>
          </Drawer>
        </div>
      </div>
    </div>
  );
}

export default AppHeader;
