import React from "react";
import { Row, Col, Card, Carousel } from "antd";

import image1 from "../../assets/images/abb.jpg";
import image2 from "../../assets/images/siemens.jpg";
import image4 from "../../assets/images/glw.jpg";
import image5 from "../../assets/images/lovato.jpg";
import image6 from "../../assets/images/danaher.jpg";
import image7 from "../../assets/images/fandis.jpg";
import image8 from "../../assets/images/emerson.jpg";
import image9 from "../../assets/images/sick.jpg";
import image10 from "../../assets/images/rotork.jpg";
import image11 from "../../assets/images/auma.jpg";
import image12 from "../../assets/images/loeche.jpg";
import image13 from "../../assets/images/ksb.jpg";
import image14 from "../../assets/images/weir.jpg";
import image15 from "../../assets/images/ahi.jpg";
import image16 from "../../assets/images/ab.jpg";
import image17 from "../../assets/images/skf.jpg";
import image18 from "../../assets/images/eh.jpg";
import image19 from "../../assets/images/eaton.jpg";

const { Meta } = Card;
function AppFeature() {
  return (
    <div id="feature" className="block featureBlock bgGray">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2> Our Suppliers</h2>
          <p> Here's our list of suppliers:</p>
        </div>

        <Carousel autoplay>
          <div>
            <Row gutter={[16, 16]}>
              <Col span={8} md={{ span: 4 }}>
                <Card
                  hoverable
                  cover={<img alt="ASEA Brown Boveri" src={image1} />}
                >
                  <Meta title="ABB" />
                </Card>
              </Col>
              <Col span={8} md={{ span: 4 }}>
                <Card hoverable cover={<img alt="SIEMENS" src={image2} />}>
                  <Meta title="SIEMENS" />
                </Card>{" "}
              </Col>
              <Col span={8} md={{ span: 4 }}>
                <Card hoverable cover={<img alt="KSB SE & Co" src={image13} />}>
                  <Meta title="KSB" />
                </Card>{" "}
              </Col>
              <Col span={8} md={{ span: 4 }}>
                <Card hoverable cover={<img alt="GLW" src={image4} />}>
                  <Meta title="GLW" />
                </Card>{" "}
              </Col>
              <Col span={8} md={{ span: 4 }}>
                <Card hoverable cover={<img alt="LOVATO" src={image5} />}>
                  <Meta title="LOVATO" />
                </Card>{" "}
              </Col>
              <Col span={8} md={{ span: 4 }}>
                <Card hoverable cover={<img alt="DANAHER" src={image6} />}>
                  <Meta title="DANAHER " />
                </Card>{" "}
              </Col>
            </Row>
          </div>
          <div>
            <Row gutter={[16, 16]}>
              <Col span={8} md={{ span: 4 }}>
                <Card hoverable cover={<img alt="FANDIS" src={image7} />}>
                  <Meta title="FANDIS " />
                </Card>{" "}
              </Col>
              <Col span={8} md={{ span: 4 }}>
                <Card hoverable cover={<img alt="EMERSON" src={image8} />}>
                  <Meta title="EMERSON" />
                </Card>{" "}
              </Col>

              <Col span={8} md={{ span: 4 }}>
                <Card
                  hoverable
                  cover={<img alt="SICK Sensor Intelligence" src={image9} />}
                >
                  <Meta title="SICK" />
                </Card>{" "}
              </Col>

              <Col span={8} md={{ span: 4 }}>
                <Card hoverable cover={<img alt="ROTORK" src={image10} />}>
                  <Meta title="ROTORK" />
                </Card>{" "}
              </Col>

              <Col span={8} md={{ span: 4 }}>
                <Card hoverable cover={<img alt="AUMA" src={image11} />}>
                  <Meta title="AUMA" />
                </Card>{" "}
              </Col>

              <Col span={8} md={{ span: 4 }}>
                <Card hoverable cover={<img alt="LOESCHE" src={image12} />}>
                  <Meta title="LOESCHE" />
                </Card>{" "}
              </Col>
            </Row>
          </div>
          <div>
            <Row gutter={[16, 16]}>
              <Col span={8} md={{ span: 4 }}>
                <Card hoverable cover={<img alt="WEIR" src={image14} />}>
                  <Meta title="WEIR" />
                </Card>{" "}
              </Col>

              <Col span={8} md={{ span: 4 }}>
                <Card hoverable cover={<img alt="AHI CARIER" src={image15} />}>
                  <Meta title="AHI CARIER" />
                </Card>{" "}
              </Col>

              <Col span={8} md={{ span: 4 }}>
                <Card hoverable cover={<img alt="ABERDARE" src={image16} />}>
                  <Meta title="ABERDARE" />
                </Card>{" "}
              </Col>
              <Col span={8} md={{ span: 4 }}>
                <Card hoverable cover={<img alt="AB SKF" src={image17} />}>
                  <Meta title="SKF" />
                </Card>{" "}
              </Col>

              <Col span={8} md={{ span: 4 }}>
                <Card
                  hoverable
                  cover={<img alt="ENDRESS + HAUSER" src={image18} />}
                >
                  <Meta title="ENDRESS + HAUSER" />
                </Card>{" "}
              </Col>

              <Col span={8} md={{ span: 4 }}>
                <Card hoverable cover={<img alt="EATON" src={image19} />}>
                  <Meta title="EATON" />
                </Card>{" "}
              </Col>
            </Row>
          </div>
        </Carousel>
      </div>
    </div>
  );
}

export default AppFeature;
