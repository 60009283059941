import React from "react";
import logo from "../../assets/images/turnibex-logo-2.png";
import { BackTop } from "antd";

function AppFooter() {
  return (
    <div className="container-fluid">
      <div className="footer">
        <div className="logo">
          <a href="#hero">
            <img alt="Turnibex-Logo" src={logo} />
          </a>
        </div>

        <ul className="socials">
          <li>
            <a href="http://www.facebook.com" target="_blank" rel="noreferrer">
              <i className="fab fa-facebook"></i>
            </a>
          </li>

          <li>
            <a href="http://www.instagram.com" target="_blank" rel="noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
          </li>

          <li>
            <a href="http://www.linkedin.com" target="_blank" rel="noreferrer">
              <i className="fab fa-linkedin"></i>
            </a>
          </li>
        </ul>

        <div className="copyright">Copyright &copy; 2022 Turnibex</div>

        <BackTop>
          <div className="goTop">
            <i className="fas fa-arrow-circle-up"></i>
          </div>
        </BackTop>
      </div>
    </div>
  );
}

export default AppFooter;
